<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>Update Input Data</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @keyup.enter="validator();submit()">
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.name.val" :description="form.name.inv" label="Name" />
                                </CCol>
                                <CCol sm="3">
                                    <label>Data Source</label>
                                    <model-select :options="data_sources" v-model="form.data_source_id.val" placeholder="Please Select">
                                    <small class="form-text text-muted w-100">{{form.data_source_id.inv}}</small>
                                    </model-select>
                                </CCol>
                                <CCol sm="3">
                                    <CTextarea v-model="form.detail.val" :description="form.detail.inv" label="Description" rows="1" />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.is_active.val"
                                        :description="form.is_active.inv"
                                        label="State"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_active.val=$event.target.value"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol v-show="data_type == 1" sm="3">
                                    <CTextarea v-model="form.tmp.val" :description="form.tmp.inv" label="Json Path" rows="1" />
                                </CCol>
                                <CCol v-show="data_type == 2" sm="3">
                                    <CSelect
                                        v-model="form.tmp.val"
                                        :description="form.tmp.inv"
                                        label="tse Type"
                                        :options="[
                                            {value: 'VOL', label:'Market Volume'},
                                            {value: 'VAL', label:'Transaction Value'},
                                            {value: 'OP', label:'Initial'},
                                            {value: 'HI', label:'Highest Price'},
                                            {value: 'LO', label:'Lowest Price '},
                                            {value: 'CL', label:'Transaction'},
                                            {value: 'C', label:'Final'},
                                            {value: 'NAV', label:'Cancellation of Transactions'},
                                            {value: 'TC', label:'Number of Transactions'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.tmp.val=$event.target.value"
                                    />
                                </CCol>
                                <CCol v-show="data_type == 3" sm="3">
                                    <CSelect
                                        v-model="form.tmp.val"
                                        :description="form.tmp.inv"
                                        label="netdania Type"
                                        :options="[
                                            {value: 'LabelLast', label:'Last'},
                                            {value: 'LabelChangeNet', label:'Change'},
                                            {value: 'LabelChangePercent', label:'Change (Percent)'},
                                            {value: 'LabelHigh', label:'Highest Price'},
                                            {value: 'LabelLow', label:'Lowest Proce'},
                                            {value: 'LabelOpen', label:'Initial Opening Price'},
                                            {value: 'LabelClose', label:'Closing Price'},
                                            {value: 'LabelYearNetChange', label:'Annual Changes'},
                                            {value: 'LabelYearPercentChange', label:'Annual Changes (Percent)'},
                                            {value: 'Label52WeekHigh', label:'Highest Rate in Last 52 Weeks'},
                                            {value: 'Label52WeekLow', label:'Lowest Rate in Last 52 Weeks'},
                                            {value: 'LabelVolume', label:'Market Volume'},
                                            {value: 'LabelVolumeInc', label:'Volume of Last Transaction '},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.tmp.val=$event.target.value"
                                    />
                                </CCol>
                                <CCol v-show="data_type == 4" sm="3">
                                    <CTextarea v-model="form.tmp.val" :description="form.tmp.inv" label="Simple HTML Tag" rows="1" />
                                </CCol>
                                <CCol v-show="data_type == 5 || data_type == 6 || data_type == 7" sm="3">
                                    <CTextarea v-model="form.tmp.val" :description="form.tmp.inv" label="Regex" rows="1" />
                                </CCol>
                                <CCol v-show="data_type == 6" sm="3">
                                    <CInput v-model="form.telegram_other_side_id.val" :description="form.telegram_other_side_id.inv" label="Telegram Other Side ID" rows="1" />
                                </CCol>
                                <CCol v-show="data_type == 7" sm="3">
                                    <CInput v-model="form.array_index.val" :description="form.array_index.inv" label="Index" />
                                </CCol>
                            </CRow>
                            <br>
                        </CForm>
                        <br>
                        <div class="form-group form-actions">
                            <CButton @click="validator();submit()" color="primary" class="mr-2"> Update</CButton>
                            <CButton color="dark" @click="go_back">Back</CButton>
                        </div>    
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select'
export default {
    components: { ModelSelect },
    data() {
        return {
            form: {
                name: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                data_source_id: {val: '', inv: '', v: { req: true, max: 1024 }},
                detail: {val: '', inv: '', v: { req: true, min: 2, max: 2048 }},
                is_active: {val: '', inv: '', v: { req: true, max: 1024 }},
                tmp: {val: '', inv: '', v: { req: true, max: 2048 }},
                telegram_other_side_id: {val: '', inv: '', v: { req: true, max: 2048 }},
                array_index: {val: '', inv: '', v: { req: true, max: 1024 }},
            },
            data_sources: [],
            disable: false,
            data_type: 0,
            remove: false,
        }
    },
    watch: {
        'form.data_source_id.val':{
            handler(){
                if(this.remove == true){
                    this.form.tmp.val = this.form.tmp.inv = '';
                }
                if(this.remove == false){
                    this.remove = true;
                }
                this.get_data_type();
            }
        }
    },
    mounted(){
        this.get_data_sources();
        this.get();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                if(this.data_type != 6 && key == 'telegram_other_side_id'){
                                    this.disable = false;
                                } else if(this.data_type != 7 && key == 'array_index'){
                                    this.disable = false;
                                } else {
                                  this.form[key].inv = 'This field is required.';
                                    this.disable = true;  
                                }
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'min':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length < this.form[key].v[k]) {
                                    this.form[key].inv = `Must be at least ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        get_data_sources(){
            this.axios.get('arbitrage/data_sources/')
                .then(res => {
                    res.data.map(val => {
                        this.data_sources.push({value: val.id, text: val.name});
                    });
                })  
        },
        get_data_type(){
            this.axios.get('arbitrage/data_sources/'
                + '?id=' + this.form.data_source_id.val)
                .then(res => {
                    this.data_type = res.data[0].data_type;
                }) 
        },
        get(){
           this.axios.get('arbitrage/input_datas/'
                + '?id=' + this.$route.params.id)
                .then(res => {
                    for(const key in res.data[0]){
                        for(const k in this.form){
                            if(key == k){
                                if(key == 'is_active'){
                                   this.form[k].val = `${res.data[0][key]}`; 
                                } else{
                                    this.form[k].val = res.data[0][key];
                                }
                            }
                        }
                    }
                    for (const key in res.data[0].pattern_info_data){
                        if(res.data[0].pattern_info_data[key] != ''){
                            if(key != 'telegram_other_side_id'
                            && key != 'array_index'){
                                this.form.tmp.val = res.data[0].pattern_info_data[key];
                            }
                        }
                    }
                    this.form.telegram_other_side_id.val = res.data[0].pattern_info_data.telegram_other_side_id;
                    this.form.array_index.val = res.data[0].pattern_info_data.array_index;
                }) 
        },
        submit(){
            if(this.disable == false){
                const data = new FormData();
                data.set('ids', JSON.stringify([parseInt(this.$route.params.id)]));
                for (const key in this.form){
                    if(key == 'tmp'){
                        switch(this.data_type){
                            case 1:
                                data.set('json_path', this.form[key].val);
                                break;
                            case 2:
                                data.set('tse_type', this.form[key].val);
                                break;
                            case 3:
                                data.set('net_dania_type', this.form[key].val);
                                break;
                            case 4:
                                data.set('simple_html_tag', this.form[key].val);
                                break;
                            case 5:
                                data.set('regex', this.form[key].val);
                                break;
                            case 6:
                                data.set('regex', this.form[key].val);
                                break;
                            case 7:
                                data.set('regex', this.form[key].val);
                                break;
                        }
                    } else if(key == 'telegram_other_side_id' && this.data_type == 6){
                        data.set(key, this.form[key].val);
                    } else if(key == 'array_index' && this.data_type == 7){
                        data.set(key, this.form[key].val);
                    } else {
                        data.set(key, this.form[key].val);
                    }
                }
                this.axios.put('arbitrage/input_datas/', data)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Updated Successfully.'
                        });
                        this.$router.push('/data/input');
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    })
            }
        },
        go_back(){
            this.$router.go(-1);
        }
    }
}
</script>
